import React, { Component } from 'react'
import { useTranslation } from 'react-i18next';
import SupportImage from '../../../images/supportImage.png'
import { Paragraph, Row, H2, Primarybutton, Col } from '../../general';
import FirstIcon from '../intro/svg/FirstIcon';
const Support = () => {
    const { t } = useTranslation();


    return (
        <div className='container mx-auto px-5'>
            <Col className='gap-5 lg:grid lg:grid-cols-2 lg:gap-10 w-full justify-center lg:items-center'>
                <div className='lg:order-2'>
                    <img className='w-full' alt='support' src={SupportImage} />
                </div>
                <Col className='gap-5 justify-start lg:order-1'>
                    <H2 className='supportH2' text={t('support.title')} />
                    <Paragraph text={t('support.subtext')} />
                    <Row className='flex flex-row gap-5'>
                        <Row >
                            <FirstIcon />
                            <Paragraph className='supportFont' text={t('support.chip1')} />
                        </Row>
                        <Row  >
                            <FirstIcon />
                            <Paragraph className='supportFont' text={t('support.chip2')} />
                        </Row>
                        <Row >
                            <FirstIcon />
                            <Paragraph className='supportFont' text={t('support.chip3')} />
                        </Row>
                    </Row>
                    <Primarybutton text={t('support.cta')} />
                </Col>

            </Col>

        </div>
    );

}

export default Support;