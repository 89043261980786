import React from 'react';


const HeaderBackground = () => {
  return (<div>

    <svg width="100%" height="100%" viewBox="0 0 1133.731 883.968">
      <defs>
        <linearGradient id="linear-gradiente" x1="1.267" y1="0.747" x2="0.428" y2="1.352" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#3376bd" />
          <stop offset="0.148" stopColor="#3e76bc" />
          <stop offset="0.455" stopColor="#d977af" />
          <stop offset="0.545" stopColor="#ec7ba3" />
          <stop offset="0.636" stopColor="#f98196" />
          <stop offset="0.727" stopColor="#ff8b88" />
          <stop offset="0.818" stopColor="#ff977c" />
          <stop offset="0.909" stopColor="#ffa672" />
          <stop offset="1" stopColor="#ffb56b" />
        </linearGradient>
      </defs>
      <path id="Path_38" data-name="Path 38" d="M785.453,0H1919.185V709.661s-776.95,295.314-932.22,117.168S785.453,0,785.453,0Z" transform="translate(-785.453)" fill="url(#linear-gradiente)" />
    </svg>



  </div>)
}

export default HeaderBackground;