import * as React from "react"
import Hero from "../components/homepage/hero/Hero"

import Navbar from "../components/navbar/Navbar"
import Intro from "../components/homepage/intro/Intro"
import Features from "../components/homepage/features/Features"
import Spacer from "../components/spacer"
import Support from "../components/homepage/support/Support"
import Footer from "../components/footer/Footer"
import { useTranslation } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector';

// markup
const IndexPage = () => {

  const { i18n } = useTranslation();


  function setLanguage(bool) {
    console.log(bool)
    if (bool) {
      return i18n.changeLanguage('nl')
    }
    return i18n.changeLanguage('en')

  }

  return (
    <main>
      <Navbar switchFunction={(bool)=>{setLanguage(bool)}} />
      <Hero />
      <Spacer />
      <Intro />
      <Spacer />
      <Features />
      <Spacer />
      <Support />
      <Spacer />
      <Footer />
    </main>
  )
}

export default IndexPage
