import React, { Component } from 'react';

class DropdownSvg extends Component {
   
    render() {
        return (
            <div className={'w-5 transition-all' + (this.props.isActive ? '' : ' rotate-180')}>
                <svg width="100%" height="100%" viewBox="0 0 20 20">
                    <path id="Icon_ionic-ios-arrow-dropdown-circle" data-name="Icon ionic-ios-arrow-dropdown-circle" d="M3.375,13.375a10,10,0,1,0,10-10A10,10,0,0,0,3.375,13.375Zm13.9-2.087a.931.931,0,0,1,1.312,0,.917.917,0,0,1,.269.654.933.933,0,0,1-.274.659L14.048,17.12a.927.927,0,0,1-1.279-.029L8.168,12.5a.928.928,0,1,1,1.313-1.312l3.9,3.937Z" transform="translate(23.375 23.375) rotate(180)" />
                </svg>
            </div>
        );
    }
}

export default DropdownSvg;