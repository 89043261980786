import React from 'react';

const FirstIcon = () => {
    return (
        <div className='w-5 my-auto'>
            <svg width="100%" height="100%" viewBox="0 0 19.925 22.158">
                <defs>
                    <linearGradient id="linear-gradientIcon" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#3376bd" />
                        <stop offset="0.148" stopColor="#3e76bc" />
                        <stop offset="0.455" stopColor="#d977af" />
                        <stop offset="0.545" stopColor="#ec7ba3" />
                        <stop offset="0.636" stopColor="#f98196" />
                        <stop offset="0.727" stopColor="#ff8b88" />
                        <stop offset="0.818" stopColor="#ff977c" />
                        <stop offset="0.909" stopColor="#ffa672" />
                        <stop offset="1" stopColor="#ffb56b" />
                    </linearGradient>
                </defs>
                <path id="Path_30" data-name="Path 30" d="M225.811,238.245c2.906.275,5.239,2.229,6.934,4.607a9.523,9.523,0,0,1,1.7,7.5,10.376,10.376,0,0,1-4.595,6.409c-2.947,2-6.314,4.537-9.624,3.227-3.438-1.36-4.656-5.48-5.284-9.124-.579-3.36-.15-6.915,2.075-9.5a10.236,10.236,0,0,1,8.8-3.12" transform="translate(-214.704 -238.188)" fill="url(#linear-gradientIcon)" />
            </svg>


        </div>
    )
}

export default FirstIcon;