import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, H3, Paragraph } from '../../general/index'
import FirstIcon from './svg/FirstIcon';
import SecondIcon from './svg/SecondIcon';
import ThirdIcon from './svg/ThirdIcon';

const Intro = () => {
    const { t } = useTranslation();

    return (
        <div className='container mx-auto px-5 '>
            <div className="introContainer flex flex-col justify-evenly gap-12 md:flex-row">
                <Col>
                    <Row>
                        <FirstIcon />
                        <H3 text={t('intro.first')}/>
                    </Row>
                    <Paragraph text={t('intro.firstSub')} />
                </Col>
                <Col>
                    <Row>
                        <SecondIcon />
                        <H3 text={t('intro.second')} />
                    </Row>
                    <Paragraph text={t('intro.secondSub')} />
                </Col>
                <Col>
                    <Row>
                        <ThirdIcon />
                        <H3 text={t('intro.third')}/>
                    </Row>
                    <Paragraph text={t('intro.thirdSub')} />
                </Col>
            </div>
        </div>

    )
}

export default Intro;