import React, { Component } from 'react';

class FeatureSection extends Component {

    render() {
        return (

            <div className='flex flex-col gap-5 '>


                <div className=''>
                    <img className='homepageFeaturesImage w-full md:h-96' src={this.props.image} alt="Image about feature"/>
                </div>
                <div className=''>
                    <h2 onClick={this.props.onClick}>{this.props.title}</h2>
                    {this.props.isActive && <p >

                        {this.props.description}
                    </p>}

                    {this.props.description}
                    <br />
                    <br />
                    <span className=' text-gray-400'><b>Replaces: </b>{this.props.replacement}</span>


                </div>
            </div>



        );
    }
}

export default FeatureSection;