import React, { Component } from 'react';
import DropdownSvg from '../svg/Dropdown';

class FeatureSectionTexts extends Component {
 



    render() {
        return (

            <div className='md:order-1 md:col-span-1 '>
                <div className='flex flex-row gap-4 transition-all'>
                    <h2 onClick={this.props.onClick}>{this.props.title}</h2>
                    <DropdownSvg isActive={this.props.isActive} />
                </div>
                <p className={(this.props.isActive ? 'featureTextAnimEnabled' : 'featureTextAnimDisabled')} >
                    {this.props.description}
                    <br />
                    <br />
                    <span className={(this.props.isActive ? ' text-gray-400' : 'featureTextAnimDisabled')}><b>Replaces: </b>{this.props.replacement}</span>
                </p>

            </div>



        );
    }
}

export default FeatureSectionTexts;