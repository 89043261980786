import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Secondarybutton, Subtext } from '../../../general';
import Arrow from '../svg/Arrow';


const FeatureSectionButtons = (props) => {
    const { t } = useTranslation();
    return (<div>
        <Row className='flex flex-row gap-3'>
            <Secondarybutton text={t('features.cta')} />
            <Col className="gap-0 items-start">
                <Subtext text={t('features.subtext')} />
                <Subtext text={t('features.subtext2')} />
            </Col>
        </Row>

        <div className='flex flex-row justify-between mt-5 lg:hidden' >
            <div className='w-10' onClick={props.left}>
                <Arrow />

            </div>
            <div className='w-10 rotate-180' onClick={props.right}>
                <Arrow />
            </div>

        </div>
    </div>);

}

export default FeatureSectionButtons;