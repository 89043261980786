import React from 'react';

const ThirdIcon = () => {
    return (
        <div className='w-5 my-auto'>
            <svg width="100%" height="100%" viewBox="0 0 25.173 22.158">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#3376bd" />
                        <stop offset="0.148" stopColor="#3e76bc" />
                        <stop offset="0.455" stopColor="#d977af" />
                        <stop offset="0.545" stopColor="#ec7ba3" />
                        <stop offset="0.636" stopColor="#f98196" />
                        <stop offset="0.727" stopColor="#ff8b88" />
                        <stop offset="0.818" stopColor="#ff977c" />
                        <stop offset="0.909" stopColor="#ffa672" />
                        <stop offset="1" stopColor="#ffb56b" />
                    </linearGradient>
                </defs>
                <path id="Path_28" data-name="Path 28" d="M226.06,238.675c2.729-.006,4.71,2.2,6.839,3.9,2.563,2.054,6.232,3.572,6.562,6.839.341,3.368-2.443,6.187-5.022,8.379a13.426,13.426,0,0,1-8.379,3.015c-3.224.114-6.691-.142-8.994-2.4a10.578,10.578,0,0,1-2.627-8.994c.4-2.862,2.692-4.846,4.814-6.807,2-1.849,4.083-3.928,6.807-3.934" transform="translate(-214.317 -238.675)" fill="url(#linear-gradient)" />
            </svg>



        </div>
    )
}

export default ThirdIcon;