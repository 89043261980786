import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeatureSection from './components/FeatureSection';
import FeatureSectionButtons from './components/FeatureSectionButtons';
import FeatureSectionTexts from './components/FeatureSectionTexts';
import InformEmployeesImage from './informEmployeesImage.png'

const Features = (props) => {
    const [activeSection, setSection] = useState(0)
    const [activeImage, setImage] = useState(InformEmployeesImage)
    const [activeSectionDesktop, setActiveSectionDesktop] = useState([
        true, false, false, false, false,
    ])


    const disabledSections = [
        false, false, false, false, false,
    ]

    const { t } = useTranslation();


    const imageList = [InformEmployeesImage, InformEmployeesImage, InformEmployeesImage, InformEmployeesImage, InformEmployeesImage, InformEmployeesImage,]
    const sections = [
        <FeatureSection
            image={InformEmployeesImage}
            title={t('features.informTitle')}
            description={t('features.informSubtext')}
            replacement={t('features.informReplacement')}

        />,
        <FeatureSection
            image={InformEmployeesImage}
            title={t('features.onboardtitle')}
            description={t('features.onboardSubtext')}
            replacement={t('features.onboardReplacement')}

        />,
        <FeatureSection
            image={InformEmployeesImage}
            title={t('features.employeesTitle')}
            description={t('features.employeesSubtext')}
            replacement={t('features.employeesReplacement')}

        />,
        <FeatureSection
            image={InformEmployeesImage}
            title={t('features.scheduleTitle')}
            description={t('features.scheduleSubtext')}
            replacement={t('features.scheduleReplacement')}

        />,
        <FeatureSection
            image={InformEmployeesImage}
            title={t('features.colleaguesTitle')}
            description={t('features.colleaguesSubtext')}
            replacement={t('features.colleaguesReplacement')}

        />,
        <FeatureSection
            image={InformEmployeesImage}
            title={t('features.brandingTitle')}
            description={t('features.brandingSubtext')}
            replacement={t('features.brandingReplacement')}
        />


    ]

    const sectionsTexts = [

        <FeatureSectionTexts
            title={t('features.informTitle')}
            description={t('features.informSubtext')}
            replacement={t('features.informReplacement')}

        />,
        <FeatureSectionTexts
            title={t('features.onboardtitle')}
            description={t('features.onboardSubtext')}
            replacement={t('features.onboardReplacement')}

        />,
        <FeatureSectionTexts
            title={t('features.employeesTitle')}
            description={t('features.employeesSubtext')}
            replacement={t('features.employeesReplacement')}

        />,
        <FeatureSectionTexts
            title={t('features.scheduleTitle')}
            description={t('features.scheduleSubtext')}
            replacement={t('features.scheduleReplacement')}

        />,
        <FeatureSectionTexts
            title={t('features.colleaguesTitle')}
            description={t('features.colleaguesSubtext')}
            replacement={t('features.colleaguesReplacement')}

        />,
        <FeatureSectionTexts
            title={t('features.brandingTitle')}
            description={t('features.brandingSubtext')}
            replacement={t('features.brandingReplacement')}
        />


    ]

    function arrowFunction(bool) {

        if (bool) {
            if (activeSection === 5) {
                setSection(0)
                return
            }
            setSection(activeSection + 1)
            return

        } else {
            if (activeSection === 0) {
                setSection(5)
                return
            }
            setSection(activeSection - 1)
            return
        }

    }




    return (
        <div className='container mx-auto px-5 mb-20'>
            <div className='featureContainer flex flex-col justify-between '>
                <div className='lg:hidden'>
                    {sections[activeSection]}
                </div>
                <div className='hidden lg:block '>
                    <div className='gap-5 grid grid-cols-3'>
                        <div className='md:order-2 md:w-full md:col-span-2'>
                            <img className='homepageFeaturesImage md:w-full md:h-full' alt='active pico' src={activeImage} />
                        </div>
                        <div className='flex flex-col gap-5'>
                            {sectionsTexts.map((item, i) => <ul key={i}>{React.cloneElement(item, {
                                isActive: activeSectionDesktop[i], onClick: () => {
                                    let updatedList = [...disabledSections];
                                    updatedList[i] = true;
                                    setActiveSectionDesktop(updatedList)
                                    setImage(imageList[i])
                                }
                            })}</ul>)}
                        </div>
                    </div>
                </div>
                <div >
                    <FeatureSectionButtons
                        right={() => arrowFunction(true)}
                        left={() => arrowFunction(false)}
                    />
                </div>
            </div>
        </div>
    );

}

export default Features;


