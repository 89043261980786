import React from 'react'
import HeaderBackground from './HeaderBackground'
import HeroImage from '../../../images/headerImage.png'
import Mouse from './MouseSvg'
import { Col, H1, Paragraph, Primarybutton, Subtext } from '../../general'
import { useTranslation } from 'react-i18next';

const Hero = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className='relative'>
            <div className='container px-5 relative z-10 pt-28 mx-auto lg:pt-36'>
                <div className='container mx-auto flex flex-col gap-5 lg:grid lg:grid-cols-12 lg:gap-20'>
                    <div className='lg:order-2 lg:col-span-7' >
                        <div className='heroBg z-0'>
                            <HeaderBackground />
                        </div>
                        <img className='mx-auto w-full z-10 relative' alt='hero relio' src={HeroImage} />
                    </div>

                    <div className='flex z-10 flex-col gap-5 lg:gap-10 lg:pl-5 lg:order-1 lg:justify-center lg:col-span-5'>
                        <div>
                            <H1 text={t('hero.heroTitle')} />
                            <H1 text={t('hero.heroTitle2')}  className='primaryTextcolor' />
                        </div>
                        <Paragraph text={t('hero.heroParagraph')}  />

                        <div className='flex flex-row gap-3 items-center'>
                           <Primarybutton text={t('hero.cta')} />
                            <Col className="gap-0">
                                <Subtext text={t('hero.subtext')} />
                                <Subtext text={t('hero.subtext2')} />
                            </Col>
                        </div>
                    </div>
                </div>
                <div className='mx-auto w-10 mt-10'>
                    <Mouse />
                </div>
            </div>

        </div>
    )
}

export default Hero;