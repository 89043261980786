import React from 'react';

const SecondIcon = () => {
  return (
    <div className='w-5 my-auto'>
      <svg width="100%" height="100%" viewBox="0 0 20.007 22.158">
        <defs>
          <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#3376bd" />
            <stop offset="0.148" stopColor="#3e76bc" />
            <stop offset="0.455" stopColor="#d977af" />
            <stop offset="0.545" stopColor="#ec7ba3" />
            <stop offset="0.636" stopColor="#f98196" />
            <stop offset="0.727" stopColor="#ff8b88" />
            <stop offset="0.818" stopColor="#ff977c" />
            <stop offset="0.909" stopColor="#ffa672" />
            <stop offset="1" stopColor="#ffb56b" />
          </linearGradient>
        </defs>
        <path id="Path_29" data-name="Path 29" d="M153.068,187.8c3.016-.067,5.644,2.1,7.22,4.678,1.365,2.23.631,4.872.208,7.453-.481,2.937-.341,6.275-2.745,8.029a10.36,10.36,0,0,1-9.965,1.244c-3.432-1.44-6.345-4.53-6.694-8.235-.318-3.374,2.866-5.673,5.146-8.181,2-2.194,3.865-4.923,6.83-4.988" transform="translate(-141.07 -187.798)" fill="url(#linear-gradient)" />
      </svg>



    </div>
  )
}

export default SecondIcon;